import i18n from '../../managers/i18n'

i18n.register(
  'zh-Hans',
  {
    placeholder: '请选择',
    clear: '清除',
    noData: '无搜索结果',
    selectAll: '全选'
  },
  {
    ns: 'cascader'
  }
)
