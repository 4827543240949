var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',[_vm._m(0),_c('section',[_c('veui-slider',{model:{value:(_vm.value1),callback:function ($$v) {_vm.value1=$$v},expression:"value1"}}),_c('veui-slider',{attrs:{"readonly":""},model:{value:(_vm.value1),callback:function ($$v) {_vm.value1=$$v},expression:"value1"}}),_c('veui-slider',{attrs:{"disabled":"","ui":"s"},scopedSlots:_vm._u([{key:"tip-label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.value1.toFixed(2)))])]},proxy:true}]),model:{value:(_vm.value1),callback:function ($$v) {_vm.value1=$$v},expression:"value1"}}),_c('div',{staticClass:"desc"},[_vm._v("Range: 0~1, Value: "+_vm._s(_vm.value1))])],1),_c('section',[_c('veui-slider',{attrs:{"ui":"s","min":10,"max":100,"step":7,"mark":""},scopedSlots:_vm._u([{key:"tip-label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.value2)+"%")])]},proxy:true}]),model:{value:(_vm.value2),callback:function ($$v) {_vm.value2=$$v},expression:"value2"}}),_c('veui-slider',{attrs:{"disabled":"","min":10,"max":100,"step":7,"mark":""},model:{value:(_vm.value2),callback:function ($$v) {_vm.value2=$$v},expression:"value2"}}),_c('div',{staticClass:"desc"},[_vm._v("Range: 10~100, Step: 7, Value: "+_vm._s(_vm.value2))]),_c('h3',[_vm._v("固定比例")]),_c('veui-slider',{attrs:{"value":50,"min":1,"max":100,"mark":""}})],1),_c('section',[_c('veui-slider',{attrs:{"min":0,"max":100},model:{value:(_vm.value4),callback:function ($$v) {_vm.value4=$$v},expression:"value4"}}),_c('div',{staticClass:"desc"},[_vm._v("Range: 0~100, Value: "+_vm._s(_vm.value4))])],1),_c('section',[_c('veui-slider',{attrs:{"min":0,"max":360,"step":1,"parse":_vm.parseColorHue,"format":_vm.formatColorHue},scopedSlots:_vm._u([{key:"thumb",fn:function(ref){
var index = ref.index;
return _c('div',{key:("thumb_" + index),staticStyle:{"margin-top":"6px"}},[_c('div',{staticStyle:{"width":"16px","height":"12px"}},[_c('svg',{attrs:{"width":"16","height":"12","viewBox":"0 0 16 12"}},[_c('polygon',{attrs:{"points":"8,0 16,12 0,12"}})])])])}},{key:"tip",fn:function(ref){
var open = ref.open;
var activeIndex = ref.activeIndex;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(open),expression:"open"}],staticClass:"custom-tip",style:({
            left: ((((activeIndex >= 0 ? _vm.parseColorHue(_vm.value3[activeIndex]) : 0) /
                360) *
              100) + "%"),
            backgroundColor: _vm.value3[activeIndex]
          })})]}}]),model:{value:(_vm.value3),callback:function ($$v) {_vm.value3=$$v},expression:"value3"}},[_c('div',{staticStyle:{"width":"100%","height":"20px"},style:({ background: _vm.colorGradient }),attrs:{"slot":"track"},slot:"track"})]),_c('div',{staticClass:"desc"},[_vm._v(" Range: 0~255, Step: 1, Value: "),_c('br'),_vm._v("[ "),_vm._l((_vm.value3),function(val,index){return _c('span',{key:("colorValue" + index)},[_vm._v(" \" "),_c('span',{style:({ color: val })},[_vm._v(_vm._s(val))]),_vm._v("\" "),(index < _vm.value3.length - 1)?_c('span',[_vm._v(",")]):_vm._e()])}),_vm._v(" ] ")],2)],1),_c('section',{staticClass:"video"},[_c('div',{staticClass:"play-button"},[_vm._v("▶️")]),_c('veui-slider',{attrs:{"secondary-progress":_vm.videoBufferProgress,"ui":"micro"},scopedSlots:_vm._u([{key:"tip-label",fn:function(){return [_c('span',[_vm._v(_vm._s(Math.round(_vm.videoPlayProgress * 100))+"%")])]},proxy:true}]),model:{value:(_vm.videoPlayProgress),callback:function ($$v) {_vm.videoPlayProgress=$$v},expression:"videoPlayProgress"}}),_c('div',{staticClass:"duration"},[_c('span',[_vm._v(_vm._s(_vm.formatDuration(_vm.videoDuration * _vm.videoPlayProgress)))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(_vm.formatDuration(_vm.videoDuration * _vm.videoBufferProgress)))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(_vm.formatDuration(_vm.videoDuration)))])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('code',[_vm._v("<veui-slider>")])])}]

export { render, staticRenderFns }