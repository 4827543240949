var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',[_vm._m(0),_c('section',[_c('veui-checkbox',{staticStyle:{"margin-left":"20px"},model:{value:(_vm.aimCenter),callback:function ($$v) {_vm.aimCenter=$$v},expression:"aimCenter"}},[_vm._v("对准中心")])],1),_c('section',[_c('div',{staticClass:"demo-wrap"},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("hover事件")]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"top"},[_c('veui-button',{ref:"topLeftHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'top-start',
                target: _vm.$refs['topLeftHover']
              })}}},[_vm._v("上左")]),_c('veui-button',{ref:"topCenterHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'top',
                target: 'topCenterHover'
              })}}},[_vm._v("上边")]),_c('veui-button',{ref:"topRightHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'top-end',
                target: 'topRightHover'
              })}}},[_vm._v("上右")])],1),_c('div',{staticClass:"left"},[_c('veui-button',{ref:"leftTopHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'left-start',
                target: 'leftTopHover'
              })}}},[_vm._v("左上")]),_c('veui-button',{ref:"leftCenterHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'left',
                target: 'leftCenterHover'
              })}}},[_vm._v("左边")]),_c('veui-button',{ref:"leftBottomHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'left-end',
                target: 'leftBottomHover'
              })}}},[_vm._v("左下")])],1),_c('div',{staticClass:"right"},[_c('veui-button',{ref:"rightTopHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'right-start',
                target: 'rightTopHover'
              })}}},[_vm._v("右上")]),_c('veui-button',{ref:"rightCenterHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'right',
                target: 'rightCenterHover'
              })}}},[_vm._v("右边")]),_c('veui-button',{ref:"rightBottomHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'right-end',
                target: 'rightBottomHover'
              })}}},[_vm._v("右下")])],1),_c('div',{staticClass:"bottom"},[_c('veui-button',{ref:"BottomLeftHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'bottom-start',
                target: 'BottomLeftHover'
              })}}},[_vm._v("下左")]),_c('veui-button',{ref:"BottomCenterHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'bottom',
                target: 'BottomCenterHover'
              })}}},[_vm._v("下边")]),_c('veui-button',{ref:"BottomRightHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'bottom-end',
                target: 'BottomRightHover'
              })}}},[_vm._v("下右")])],1)])]),_c('veui-popover',{attrs:{"ui":"s","position":_vm.position,"target":_vm.target,"open":_vm.open,"overlay-options":_vm.overlayOptions,"overlay-style":{
        '--dls-dropdown-max-display-items': 8
      },"aim-center":_vm.aimCenter,"trigger":"hover"},on:{"update:open":function($event){_vm.open=$event}}},[_vm._v("当前是hover事件")])],1),_c('section',[_c('div',{staticClass:"demo-wrap"},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("click事件")]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"top"},[_c('veui-button',{ref:"topLeftClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'top-start',
                clickTarget: 'topLeftClick'
              })}}},[_vm._v("上左")]),_c('veui-button',{ref:"topCenterClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'top',
                clickTarget: 'topCenterClick'
              })}}},[_vm._v("上边")]),_c('veui-button',{ref:"topRightClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'top-end',
                clickTarget: 'topRightClick'
              })}}},[_vm._v("上右")])],1),_c('div',{staticClass:"left"},[_c('veui-button',{ref:"leftTopClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'left-start',
                clickTarget: 'leftTopClick'
              })}}},[_vm._v("左上")]),_c('veui-button',{ref:"leftCenterClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'left',
                clickTarget: 'leftCenterClick'
              })}}},[_vm._v("左边")]),_c('veui-button',{ref:"leftBottomClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'left-end',
                clickTarget: 'leftBottomClick'
              })}}},[_vm._v("左下")])],1),_c('div',{staticClass:"right"},[_c('veui-button',{ref:"rightTopClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'right-start',
                clickTarget: 'rightTopClick'
              })}}},[_vm._v("右上")]),_c('veui-button',{ref:"rightCenterClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'right',
                clickTarget: 'rightCenterClick'
              })}}},[_vm._v("右边")]),_c('veui-button',{ref:"rightBottomClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'right-end',
                clickTarget: 'rightBottomClick'
              })}}},[_vm._v("右下")])],1),_c('div',{staticClass:"bottom"},[_c('veui-button',{ref:"BottomLeftClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'bottom-start',
                clickTarget: 'BottomLeftClick'
              })}}},[_vm._v("下左")]),_c('veui-button',{ref:"BottomCenterClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'bottom',
                clickTarget: 'BottomCenterClick'
              })}}},[_vm._v("下边")]),_c('veui-button',{ref:"BottomRightClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'bottom-end',
                clickTarget: 'BottomRightClick'
              })}}},[_vm._v("下右")])],1)])]),_c('veui-popover',{attrs:{"ui":"m","title":"标题","position":_vm.clickPosition,"target":_vm.clickTarget,"open":_vm.clickOpen,"aim-center":_vm.aimCenter,"footless":false,"trigger":"click"},on:{"update:open":function($event){_vm.clickOpen=$event}}},[_vm._v("当前是click事件")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('code',[_vm._v("<veui-popover>")])])}]

export { render, staticRenderFns }