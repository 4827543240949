import i18n from '../../managers/i18n'

i18n.register(
  'zh-Hans',
  {
    remove: '删除'
  },
  {
    ns: 'tag'
  }
)
