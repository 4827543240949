import i18n from '../../managers/i18n'

i18n.register(
  'zh-Hans',
  {
    clear: '清除'
  },
  {
    ns: 'taginput'
  }
)
