var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{ref:"group",class:( _obj = {}, _obj[_vm.$c('cascader-pane')] = true, _obj[_vm.$c('cascader-pane-inline')] = _vm.inline && !_vm.realColumnWidth, _obj[_vm.$c('cascader-pane-custom-width')] = !!_vm.realColumnWidth, _obj ),style:(_vm.realColumnWidth
      ? { '--veui-cascader-pane-custom-width': _vm.realColumnWidth }
      : null),attrs:{"tag":"div","name":_vm.$c('cascader-pane'),"ui":_vm.realUi}},_vm._l((_vm.expandedItems),function(group,depth){return _c('div',{key:("g" + depth),ref:"menu",refInFor:true,class:_vm.$c('cascader-pane-column-wrap'),style:(_vm.getPaneItemStyle(depth))},[_c('div',{class:_vm.$c('cascader-pane-column'),attrs:{"ui":_vm.realUi}},[(_vm.$scopedSlots['column-before'])?_c('div',{class:_vm.$c('cascader-pane-column-before')},[_vm._t("column-before",null,null,{
            option: group
          })],2):_vm._e(),_c('abstract-tree',{class:_vm.$c('cascader-pane-tree'),attrs:{"children-key":"options","items":group.options,"expand":_vm.expand,"group-class":_vm.$c('cascader-pane-tree')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var _obj;

          var option = ref.item;
          var parents = ref.parents;return [_c('div',{class:( _obj = {}, _obj[_vm.$c('cascader-pane-option-wrap')] = true, _obj[_vm.$c('cascader-pane-option-wrap-expanded')] = _vm.isExpanded(option), _obj[_vm.$c('cascader-pane-option-wrap-popout')] = _vm.canPopOut(option), _obj[_vm.$c('cascader-pane-option-wrap-disabled')] = _vm.isDisabled(
                option,
                parents,
                group.parents
              ), _obj[_vm.$c('cascader-pane-option-wrap-selected')] = _vm.isSelected(option), _obj[_vm.$c('cascader-pane-option-wrap-indeterminate')] = option.partialChecked, _obj ),attrs:{"data-kbd-level":depth + 1,"data-kbd-key":_vm.getKey(option),"data-kbd-next":_vm.canPopOut(option) && _vm.isClickTrigger,"tabindex":option.disabled ? -1 : 0},on:{"click":function($event){return _vm.handleClick(option)},"mouseenter":function($event){return _vm.handleExpand(option, depth, 'hover')}}},[_vm._t("option",function(){
            var _obj;
return [_c('div',{ref:"button",refInFor:true,class:( _obj = {}, _obj[_vm.$c('cascader-pane-group-label')] = _vm.hasChildren(option) || _vm.needLoad(option), _obj[_vm.$c('cascader-pane-option')] = true, _obj ),attrs:{"ui":_vm.realUi}},[(_vm.multiple)?_c('veui-checkbox',{attrs:{"tabindex":"-1","ui":_vm.uiParts.checkbox,"checked":option.checked,"indeterminate":option.partialChecked,"disabled":_vm.isDisabled(option, parents, group.parents)},on:{"change":function($event){return _vm.handleSelect(option)}},nativeOn:{"click":function($event){$event.stopPropagation();}}}):_vm._e(),_c('div',{class:_vm.$c('cascader-pane-option-label')},[_vm._t("option-label",function(){return [_vm._v(_vm._s(option.label))]},null,Object.assign({}, _vm.slotProps,
                      {option: option}))],2),(_vm.canPopOut(option))?[(_vm.isClickTrigger)?_c('veui-button',{class:_vm.$c('cascader-pane-expandable'),attrs:{"ui":"icon","data-kbd-level":depth + 1,"data-kbd-next":true,"data-kbd-key":((_vm.getKey(option)) + "-expandable")},on:{"click":function($event){return _vm.handleExpand(option, depth, 'click')}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[(_vm.isLoading(option))?_c('veui-loading',{attrs:{"loading":""}}):_c('veui-icon',{attrs:{"name":_vm.icons.expandable}})],1):(_vm.isLoading(option))?_c('veui-loading',{attrs:{"loading":""}}):_c('veui-icon',{class:_vm.$c('cascader-pane-expandable'),attrs:{"name":_vm.icons.expandable}})]:_vm._e()],2)]},null,Object.assign({}, _vm.slotProps,
                {option: option}))],2)]}}],null,true)}),(_vm.$scopedSlots['column-after'])?_c('div',{class:_vm.$c('cascader-pane-column-after')},[_vm._t("column-after",null,null,{
            option: group
          })],2):_vm._e()],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }