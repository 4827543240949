import { render, staticRenderFns } from "./Colors.vue?vue&type=template&id=1a74ca48&scoped=true&"
import script from "./Colors.vue?vue&type=script&lang=js&"
export * from "./Colors.vue?vue&type=script&lang=js&"
import style0 from "./Colors.vue?vue&type=style&index=0&id=1a74ca48&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.8_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_vue-template-compiler@2.6.14_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a74ca48",
  null
  
)

export default component.exports