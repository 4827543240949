var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.updateLayout),expression:"updateLayout"}],class:( _obj = {}, _obj[_vm.$c('table')] = true, _obj[_vm.$c('table-bordered')] = _vm.headerBordered || _vm.bodyBordered, _obj[_vm.$c('table-has-bordered-header')] = _vm.headerBordered, _obj[_vm.$c('table-has-bordered-body')] = _vm.bodyBordered, _obj[_vm.$c('table-hit-top')] = _vm.hit.top, _obj[_vm.$c('table-hit-right')] = _vm.hit.right, _obj[_vm.$c('table-hit-bottom')] = _vm.hit.bottom, _obj[_vm.$c('table-hit-left')] = _vm.hit.left, _obj[_vm.$c('table-overflow-left-edge')] = _vm.scrollableX &&
      (!_vm.supportSticky || (!_vm.needFixLeft && !_vm.selectable && !_vm.expandable)), _obj[_vm.$c('table-overflow-right-edge')] = _vm.scrollableX && (!_vm.supportSticky || !_vm.hasFixedRight), _obj[_vm.$c('table-has-loading-backdrop')] = _vm.hasLoadingBackdrop, _obj ),attrs:{"ui":_vm.realUi},on:{"focusin":_vm.handleFocusIn}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._t("default")],2),_c('div',{ref:"fixedHeader",class:_vm.$c('table-fixed-header')},[_c('table',{style:({
        minWidth: _vm.scrollableX
          ? ("calc(" + (_vm.realScroll.x) + " + " + _vm.scrollbarWidth + "px)")
          : null
      })},[_c('col-group',{attrs:{"gutter":""}}),_c('table-head',{ref:"head",on:{"sort":_vm.sort}})],1)]),(_vm.realLoadingOptions.type !== 'spinner')?_c('div',{class:_vm.$c('table-loading-row'),attrs:{"aria-hidden":"true"}},[_c('veui-loading-bar',{class:_vm.$c('table-loading'),attrs:{"ui":_vm.uiParts.loading,"loading":_vm.loading}}),(!_vm.data.length && _vm.loading)?_c('div',{class:_vm.$c('table-no-data-loading')},[_vm._v(" "+_vm._s(_vm.t('loading'))+" ")]):_vm._e()],1):_vm._e(),_c('div',{ref:"main",class:_vm.$c('table-main'),style:({
      maxHeight: _vm.scrollableY ? _vm.realScroll.y : null
    })},[_c('table',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.updateLayout),expression:"updateLayout"}],ref:"table",style:({
        minWidth: _vm.scrollableX ? _vm.realScroll.x : null
      })},[_c('col-group'),_c('table-body',[_c('template',{slot:"no-data"},[_vm._t("no-data",function(){return [_vm._v(_vm._s(_vm.t('noData')))]})],2)],2)],1)]),_c('div',{class:_vm.$c('table-sticky-scrollbar'),style:(( _obj$1 = {}, _obj$1[("--" + (_vm.$c('table-scroll-width')))] = (_vm.scrollWidth + "px"), _obj$1[("--" + (_vm.$c('table-scrollbar-width')))] = (_vm.scrollbarWidth + "px"), _obj$1[("--" + (_vm.$c('table-scrollbar-height')))] = (_vm.scrollbarHeight + "px"), _obj$1 )),attrs:{"aria-hidden":"true"}},[_c('div',{ref:"scrollbar",class:_vm.$c('table-sticky-scrollbar-placeholder'),on:{"scroll":_vm.syncMainScroll}})]),(_vm.hasFoot())?_c('div',{ref:"fixedFooter",class:_vm.$c('table-fixed-footer')},[_c('table',{style:({
        minWidth: _vm.scrollableX
          ? ("calc(" + (_vm.realScroll.x) + " + " + _vm.scrollbarWidth + "px)")
          : null
      })},[_c('col-group',{attrs:{"gutter":""}}),_c('table-foot',{ref:"foot"},[_vm._t("foot")],2)],1)]):_vm._e(),_c('transition',{attrs:{"name":_vm.$c('table-loading')}},[(_vm.realLoadingOptions.type === 'spinner' && _vm.loading)?_c('veui-loading',{attrs:{"loading":"","ui":_vm.uiParts.spinner}},[_vm._v(_vm._s(_vm.t('loading')))]):_vm._e()],1),_c('div',{class:_vm.$c('table-loading-backdrop'),attrs:{"aria-hidden":"true"}}),_c('div',{class:_vm.$c('table-overflow-shadow'),attrs:{"aria-hidden":"true"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }