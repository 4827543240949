import ui from 'veui/managers/ui'

ui.defaults(
  {
    columns: 24,
    gutter: 20,
    margin: 20
  },
  'gridcontainer'
)
