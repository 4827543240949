<template>
<div :class="$c('layout-content')">
  <slot/>
</div>
</template>

<script>
import 'veui/locale/zh-Hans/common.js'
import 'veui/locale/en-US/common.js'
import 'focus-visible'

import prefix from '../../mixins/prefix'

export default {
  name: 'veui-content',
  mixins: [prefix]
}
</script>
<style lang="less" src="veui-theme-dls/components/content.less"></style>
