var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$c('region-picker'),attrs:{"ui":_vm.realUi,"role":"tree","aria-multiselectable":"true","aria-label":_vm.t('description')}},[_c('div',{ref:"focus",class:_vm.$c('sr-only'),attrs:{"tabindex":"0"},on:{"focus":_vm.initFocus}}),_vm._l((_vm.localDatasource),function(section,si){return _c('div',{key:si,class:_vm.$c('region-picker-section'),attrs:{"role":"treeitem","aria-level":"1","aria-expanded":_vm.localDatasource.length > 0,"aria-setsize":_vm.localDatasource.length,"aria-posinset":si + 1,"aria-checked":section.indeterminate ? 'mixed' : section.selected,"aria-selected":section.id != null &&
        section.selected &&
        (_vm.includeIndeterminate || !section.indeterminate)}},[_c('div',{class:_vm.$c('region-picker-section-title')},[_c('veui-checkbox',{ref:("node-" + si),refInFor:true,attrs:{"checked":section.selected,"indeterminate":section.indeterminate,"readonly":_vm.realReadonly,"disabled":_vm.realDisabled || section.disabled,"tabindex":"-1"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }$event.preventDefault();return _vm.focusDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.focusStep()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.focusStep(false)}],"change":function (checked) { return _vm.toggleNode(section, checked); }}},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(section.label)+" ")]},{"level":0},section)],2)],1),(section.children)?_c('div',{class:_vm.$c('region-picker-section-content')},_vm._l((section.children),function(branch,bi){return _c('div',{key:bi,class:_vm.$c('region-picker-branch'),attrs:{"role":"treeitem","aria-level":"2","aria-expanded":section.children.length > 0,"aria-setsize":section.children.length,"aria-posinset":bi + 1,"aria-checked":branch.indeterminate ? 'mixed' : branch.selected,"aria-selected":branch.id != null &&
            branch.selected &&
            (_vm.includeIndeterminate || !branch.indeterminate)}},[_c('div',{class:_vm.$c('region-picker-branch-title')},[_c('veui-checkbox',{ref:("node-" + si + "-" + bi),refInFor:true,attrs:{"checked":branch.selected,"indeterminate":branch.indeterminate,"readonly":_vm.realReadonly,"disabled":_vm.realDisabled || branch.disabled,"tabindex":"-1"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.preventDefault();return _vm.focusUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }$event.preventDefault();return _vm.focusDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.focusStep.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.focusStep(false)}],"change":function (checked) { return _vm.toggleNode(branch, checked); }}},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(branch.label)+" ")]},{"level":1},branch)],2)],1),(branch.children)?_c('div',{class:_vm.$c('region-picker-branch-content')},_vm._l((branch.children),function(group,gi){return _c('div',{key:gi,class:_vm.$c('region-picker-group'),attrs:{"role":"treeitem","aria-level":"3","aria-expanded":branch.children.length > 0 && !!group.active,"aria-setsize":branch.children.length,"aria-posinset":gi + 1,"aria-checked":group.indeterminate ? 'mixed' : group.selected,"aria-selected":group.id != null &&
                group.selected &&
                (_vm.includeIndeterminate || !group.indeterminate),"aria-owns":(_vm.id + "-shadow " + _vm.id + "-units")}},[_c('div',{class:_vm.$c('region-picker-group-title')},[_c('veui-checkbox',{ref:("node-" + si + "-" + bi + "-" + gi),refInFor:true,attrs:{"checked":group.selected,"readonly":_vm.realReadonly,"disabled":_vm.realDisabled || group.disabled,"indeterminate":group.indeterminate,"tabindex":"-1"},on:{"change":function (checked) { return _vm.toggleNode(group, checked); },"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.preventDefault();return _vm.focusUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }$event.preventDefault();return _vm.focusGroup(group, true)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.focusStep.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.focusStep(false)}]},nativeOn:{"mouseenter":function($event){return _vm.toggleActive(group, true)}}},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(group.label)+" ")]},{"level":2},group)],2),(group.children && group.active)?_c('veui-overlay',{attrs:{"open":group.active,"overlay-class":_vm.overlayClass,"target":("shadow-checker-" + si + "-" + bi + "-" + gi),"position":"bottom-start"},on:{"update:open":function($event){return _vm.$set(group, "active", $event)}}},[_c('div',{directives:[{name:"outside",rawName:"v-outside",value:({
                    handler: _vm.getHideFn((si + "-" + bi + "-" + gi), group),
                    refs: [("shadow-" + si + "-" + bi + "-" + gi)],
                    trigger: 'hover',
                    delay: 200
                  }),expression:"{\n                    handler: getHideFn(`${si}-${bi}-${gi}`, group),\n                    refs: [`shadow-${si}-${bi}-${gi}`],\n                    trigger: 'hover',\n                    delay: 200\n                  }"}],ref:("layer-" + si + "-" + bi + "-" + gi),refInFor:true,class:_vm.$c('region-picker-units'),attrs:{"id":(_vm.id + "-units")}},[_vm._l((Math.ceil(group.children.length / 3)),function(ri){return [_c('div',{key:ri,class:_vm.$c('region-picker-unit-row')},_vm._l((group.children.slice(
                          ri * 3 - 3,
                          ri * 3
                        )),function(unit,ui){return _c('div',{key:ui,class:_vm.$c('region-picker-unit'),attrs:{"role":"treeitem","aria-level":"4","aria-expanded":"true","aria-setsize":group.children.length,"aria-posinset":ri * 3 + ui - 2,"aria-checked":unit.selected,"aria-selected":unit.id != null && unit.selected}},[_c('veui-checkbox',{ref:("node-" + si + "-" + bi + "-" + gi + "-" + (ri * 3 - 3 + ui)),refInFor:true,attrs:{"checked":unit.selected,"indeterminate":unit.indeterminate,"readonly":_vm.realReadonly,"disabled":_vm.realDisabled || unit.disabled,"tabindex":"-1","aria-haspopup":"tree"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.preventDefault();return _vm.focusUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.focusStep.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.focusStep(false)}],"change":function (checked) { return _vm.toggleNode(unit, checked); }}},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(unit.label)+" ")]},{"level":3},unit)],2)],1)}),0)]})],2)]):_vm._e(),(group.children && group.active)?_c('veui-overlay',{attrs:{"open":group.active,"overlay-class":_vm.mergeOverlayClass(_vm.$c('region-picker-group-shadow-overlay')),"target":("node-" + si + "-" + bi + "-" + gi),"position":"bottom-start"},on:{"update:open":function($event){return _vm.$set(group, "active", $event)}}},[_c('div',{directives:[{name:"outside",rawName:"v-outside",value:({
                    handler: _vm.getHideFn((si + "-" + bi + "-" + gi), group),
                    refs: [("layer-" + si + "-" + bi + "-" + gi)],
                    trigger: 'hover',
                    delay: 200
                  }),expression:"{\n                    handler: getHideFn(`${si}-${bi}-${gi}`, group),\n                    refs: [`layer-${si}-${bi}-${gi}`],\n                    trigger: 'hover',\n                    delay: 200\n                  }"}],ref:("shadow-" + si + "-" + bi + "-" + gi),refInFor:true,class:_vm.$c('region-picker-group-shadow'),attrs:{"id":(_vm.id + "-shadow"),"role":"treeitem","aria-level":"3","aria-setsize":branch.children.length,"aria-posinset":gi + 1,"aria-checked":group.indeterminate ? 'mixed' : group.selected,"aria-selected":group.id != null &&
                      group.selected &&
                      (_vm.includeIndeterminate || !group.indeterminate),"aria-owns":(_vm.id + "-units")}},[_c('veui-checkbox',{ref:("shadow-checker-" + si + "-" + bi + "-" + gi),refInFor:true,attrs:{"checked":group.selected,"indeterminate":group.indeterminate,"readonly":_vm.realReadonly,"tabindex":"-1"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.preventDefault();return _vm.focusGroup(group)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }$event.preventDefault();return _vm.focusDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.focusStep.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.focusStep(false)}],"change":function (checked) { return _vm.toggleNode(group, checked); }}},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(group.label)+" "),(group.children && group.children.length)?_c('small',[_vm._v("("+_vm._s(group.solidCount)+"/"+_vm._s(group.children.length)+")")]):_vm._e()]},{"overlay":"","level":2},group)],2)],1)]):_vm._e()],1)])}),0):_vm._e()])}),0):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }