import i18n from '../../managers/i18n'

i18n.register(
  'zh-Hans',
  {
    search: '搜索'
  },
  {
    ns: 'searchbox'
  }
)
