<template>
<div :class="$c('grid-row')" :style="style">
  <slot/>
</div>
</template>

<script>
import 'veui/locale/zh-Hans/common.js'
import 'veui/locale/en-US/common.js'
import 'focus-visible'

import prefix from '../../mixins/prefix'
import '../../common/global'

export default {
  name: 'veui-grid-row',
  mixins: [prefix],
  provide () {
    let { columns, gutter } = this

    return {
      columns,
      gutter
    }
  },
  inject: ['columns', 'gutter'],
  computed: {
    style () {
      let { gutter } = this
      return {
        'margin-right': `-${gutter / 2}px`,
        'margin-left': `-${gutter / 2}px`
      }
    }
  }
}
</script>
<style lang="less" src="veui-theme-dls/components/grid-row.less"></style>
