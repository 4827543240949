import i18n from '../../managers/i18n'

i18n.register(
  'en-US',
  {
    loading: 'Loading…'
  },
  {
    ns: 'loading'
  }
)
