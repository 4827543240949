var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',[_vm._m(0),_c('section',[_c('veui-checkbox',{attrs:{"true-value":"reverse","false-value":""},model:{value:(_vm.ui),callback:function ($$v) {_vm.ui=$$v},expression:"ui"}},[_vm._v("反色皮肤")]),_c('veui-checkbox',{staticStyle:{"margin-left":"20px"},model:{value:(_vm.aimCenter),callback:function ($$v) {_vm.aimCenter=$$v},expression:"aimCenter"}},[_vm._v("对准中心")])],1),_c('section',[_c('div',{staticClass:"demo-wrap"},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("hover事件")]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"top"},[_c('veui-button',{ref:"topLeftHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'top-start',
                target: 'topLeftHover'
              })}}},[_vm._v("上左")]),_c('veui-button',{ref:"topCenterHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'top',
                target: 'topCenterHover'
              })}}},[_vm._v("上边")]),_c('veui-button',{ref:"topRightHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'top-end',
                target: 'topRightHover'
              })}}},[_vm._v("上右")])],1),_c('div',{staticClass:"left"},[_c('veui-button',{ref:"leftTopHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'left-start',
                target: 'leftTopHover'
              })}}},[_vm._v("左上")]),_c('veui-button',{ref:"leftCenterHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'left',
                target: 'leftCenterHover'
              })}}},[_vm._v("左边")]),_c('veui-button',{ref:"leftBottomHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'left-end',
                target: 'leftBottomHover'
              })}}},[_vm._v("左下")])],1),_c('div',{staticClass:"right"},[_c('veui-button',{ref:"rightTopHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'right-start',
                target: 'rightTopHover'
              })}}},[_vm._v("右上")]),_c('veui-button',{ref:"rightCenterHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'right',
                target: 'rightCenterHover'
              })}}},[_vm._v("右边")]),_c('veui-button',{ref:"rightBottomHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'right-end',
                target: 'rightBottomHover'
              })}}},[_vm._v("右下")])],1),_c('div',{staticClass:"bottom"},[_c('veui-button',{ref:"BottomLeftHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'bottom-start',
                target: 'BottomLeftHover'
              })}}},[_vm._v("下左")]),_c('veui-button',{ref:"BottomCenterHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'bottom',
                target: 'BottomCenterHover'
              })}}},[_vm._v("下边")]),_c('veui-button',{ref:"BottomRightHover",attrs:{"ui":"aux"},nativeOn:{"mouseenter":function($event){return _vm.show({
                position: 'bottom-end',
                target: 'BottomRightHover'
              })}}},[_vm._v("下右")])],1)])]),_c('veui-tooltip',{attrs:{"position":_vm.position,"ui":_vm.ui,"target":_vm.target,"open":_vm.open,"overlay-options":_vm.overlayOptions,"aim-center":_vm.aimCenter,"trigger":"hover"},on:{"update:open":function($event){_vm.open=$event}}},[_vm._v("当前是hover事件")])],1),_c('section',[_c('div',{staticClass:"demo-wrap"},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("click事件")]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"top"},[_c('veui-button',{ref:"topLeftClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'top-start',
                clickTarget: 'topLeftClick'
              })}}},[_vm._v("上左")]),_c('veui-button',{ref:"topCenterClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'top',
                clickTarget: 'topCenterClick'
              })}}},[_vm._v("上边")]),_c('veui-button',{ref:"topRightClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'top-end',
                clickTarget: 'topRightClick'
              })}}},[_vm._v("上右")])],1),_c('div',{staticClass:"left"},[_c('veui-button',{ref:"leftTopClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'left-start',
                clickTarget: 'leftTopClick'
              })}}},[_vm._v("左上")]),_c('veui-button',{ref:"leftCenterClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'left',
                clickTarget: 'leftCenterClick'
              })}}},[_vm._v("左边")]),_c('veui-button',{ref:"leftBottomClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'left-end',
                clickTarget: 'leftBottomClick'
              })}}},[_vm._v("左下")])],1),_c('div',{staticClass:"right"},[_c('veui-button',{ref:"rightTopClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'right-start',
                clickTarget: 'rightTopClick'
              })}}},[_vm._v("右上")]),_c('veui-button',{ref:"rightCenterClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'right',
                clickTarget: 'rightCenterClick'
              })}}},[_vm._v("右边")]),_c('veui-button',{ref:"rightBottomClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'right-end',
                clickTarget: 'rightBottomClick'
              })}}},[_vm._v("右下")])],1),_c('div',{staticClass:"bottom"},[_c('veui-button',{ref:"BottomLeftClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'bottom-start',
                clickTarget: 'BottomLeftClick'
              })}}},[_vm._v("下左")]),_c('veui-button',{ref:"BottomCenterClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'bottom',
                clickTarget: 'BottomCenterClick'
              })}}},[_vm._v("下边")]),_c('veui-button',{ref:"BottomRightClick",attrs:{"ui":"aux"},nativeOn:{"click":function($event){return _vm.clickShow({
                clickPosition: 'bottom-end',
                clickTarget: 'BottomRightClick'
              })}}},[_vm._v("下右")])],1)])]),_c('veui-tooltip',{attrs:{"position":_vm.clickPosition,"ui":_vm.ui,"target":_vm.clickTarget,"open":_vm.clickOpen,"aim-center":_vm.aimCenter,"trigger":"click"},on:{"update:open":function($event){_vm.clickOpen=$event}}},[_vm._v("当前是click事件")])],1),_c('section',[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("自定义事件")]),_c('veui-input',{ref:"number",attrs:{"ui":"big","composition":""},on:{"change":function($event){return _vm.log('change')},"focus":function($event){_vm.numberOpen = true},"blur":function($event){_vm.numberOpen = false}},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}}),_c('veui-tooltip',{attrs:{"position":"top","ui":_vm.ui,"target":"number","trigger":"custom","overlay-style":{
        '--dls-dropdown-max-display-items': 8
      },"open":_vm.numberOpen,"aim-center":_vm.aimCenter},on:{"update:open":function($event){_vm.numberOpen=$event}}},[_vm._v("你focus到了")])],1),_c('section',[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("排除自己")]),_c('veui-button',{ref:"exclude"},[_vm._v("target")]),_c('veui-tooltip',{attrs:{"position":"top","target":"exclude","trigger":"hover","interactive":false,"hide-delay":0,"aim-center":_vm.aimCenter}},[_vm._v("你focus到了")])],1),_c('section',[_vm._m(1),_c('section',{staticClass:"group"},[_c('mark',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.descA,
          disabled: !_vm.showDesc
        }),expression:"{\n          content: descA,\n          disabled: !showDesc\n        }"}]},[_vm._v("[A]")]),_c('mark',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.descB,
          disabled: !_vm.showDesc
        }),expression:"{\n          content: descB,\n          disabled: !showDesc\n        }"}]},[_vm._v("[B]")]),_c('veui-button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-start",value:('Change content of A and B'),expression:"'Change content of A and B'",modifiers:{"bottom-start":true}}],on:{"click":_vm.changeDesc}},[_vm._v("Change")]),_c('veui-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Toggle content of A and B'),expression:"'Toggle content of A and B'"}],on:{"click":function($event){_vm.showDesc = !_vm.showDesc}}},[_vm._v(_vm._s(_vm.showDesc ? 'Disable' : 'Enable'))])],1)]),_c('section',[_vm._m(2),_c('section',{staticClass:"group overflow"},[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip.overflow",value:({
          content: _vm.descA,
          disabled: !_vm.showDesc
        }),expression:"{\n          content: descA,\n          disabled: !showDesc\n        }",modifiers:{"overflow":true}}],staticClass:"ellipsis"},[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore nisi suscipit saepe ipsum enim mollitia voluptates, blanditiis aperiam vel facere assumenda hic libero iusto, at soluta magni cum in voluptatibus? ")]),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip.overflow",value:({
          content: _vm.descA,
          disabled: !_vm.showDesc
        }),expression:"{\n          content: descA,\n          disabled: !showDesc\n        }",modifiers:{"overflow":true}}],staticClass:"ellipsis"},[_vm._v(" Lorem ipsum. ")]),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip.overflow",value:({
          content: _vm.descB,
          disabled: !_vm.showDesc
        }),expression:"{\n          content: descB,\n          disabled: !showDesc\n        }",modifiers:{"overflow":true}}],staticClass:"line-clamp"},[_vm._v(" Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repudiandae quia modi, architecto sunt dolorem provident? Hic similique, at corrupti dolorem, tempore libero magni accusantium aut repellat cum fuga quidem et. ")]),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip.overflow",modifiers:{"overflow":true}}],staticClass:"line-clamp"},[_vm._v(" Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repudiandae quia modi, architecto sunt dolorem provident? Hic similique, at corrupti dolorem, tempore libero magni accusantium aut repellat cum fuga quidem et. ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('code',[_vm._v("<veui-tooltip>")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',[_c('code',[_vm._v("v-tooltip")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',[_c('code',[_vm._v("v-tooltip.overflow")])])}]

export { render, staticRenderFns }