import i18n from '../../managers/i18n'

i18n.register(
  'zh-Hans',
  {
    noData: '暂无数据'
  },
  {
    ns: 'empty'
  }
)
