import i18n from '../../managers/i18n'

i18n.register(
  'zh-Hans',
  {
    description: '地域选择，按 Tab 键在同一层级内导航，按左右箭头键切换层级'
  },
  {
    ns: 'regionpicker'
  }
)
