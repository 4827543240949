var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('veui-filter-panel',{ref:"selected",class:( _obj = {}, _obj[_vm.$c('transfer-selected-panel')] = true, _obj[_vm.$c('transfer-selected-flat')] = _vm.showMode === 'flat', _obj ),attrs:{"datasource":_vm.showMode === 'flat' ? _vm.flattenOptions : _vm.datasource,"searchable":false,"placeholder":_vm.placeholder,"ui":_vm.ui},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var items = ref.items;
  var keyword = ref.keyword;
return [_c('veui-tree',{class:_vm.$c('transfer-selected-tree'),attrs:{"datasource":items,"expanded":_vm.expanded,"disabled":!_vm.isSelectable},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_vm._t("item",null,null,props)]}},{key:"item-label",fn:function(props){return [(_vm.showMode === 'tree')?_vm._t("item-label",null,null,Object.assign({}, props, {keyword: keyword})):[_vm._l((props.path),function(item,i){return [_c('span',{key:'l-' + item.value,class:_vm.$c('transfer-selected-flat-option-label')},[_vm._t("item-label",function(){return [_vm._v(_vm._s(item.label))]},{"index":i},Object.assign({}, {item: item}, item, {keyword: keyword}))],2),(i < props.path.length - 1)?_c('veui-icon',{key:'s-' + item.value,class:_vm.$c('transfer-selected-flat-separator'),attrs:{"name":_vm.icons.separator}}):_vm._e()]})]]}},{key:"item-after",fn:function(ref){
  var item = ref.item;
  var disabled = ref.disabled;
  var parents = ref.parents;
return [_c('veui-button',{class:_vm.$c('tree-item-remove'),attrs:{"ui":_vm.uiParts.remove,"disabled":!_vm.isSelectable || !!disabled || parents.some(function (i) { return !!i.disabled; })},on:{"click":function($event){$event.stopPropagation();return _vm.remove(item, parents)}}},[_c('veui-icon',{attrs:{"aria-label":_vm.t('@transfer.remove'),"name":_vm.icons.remove}})],1)]}}],null,true)})]}}])},[_c('template',{slot:"head"},[_vm._t("head",function(){return [_vm._t("title",function(){return [_vm._v(_vm._s(_vm.realTitle))]},null,_vm.slotApi),_c('veui-button',{class:_vm.$c('transfer-remove-all'),attrs:{"ui":_vm.uiParts.removeAll,"disabled":!_vm.isSelectable || !_vm.datasource.length},on:{"click":_vm.removeAll}},[_vm._v(_vm._s(_vm.t('@transfer.deselectAll')))])]},null,_vm.slotApi)],2),_c('template',{slot:"no-data"},[_vm._t("no-data",function(){return [_vm._v(_vm._s(_vm.t('@transfer.select')))]})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }