var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('veui-overlay',_vm._g({ref:"overlay",class:_vm.$c('dialog'),attrs:{"open":_vm.realOpen,"overlay-class":_vm.mergeOverlayClass(( _obj = {}, _obj[_vm.$c('dialog-box')] = true, _obj[_vm.$c('dialog-box-mask')] = _vm.modal, _obj[_vm.$c('dialog-inline')] = _vm.inline, _obj )),"overlay-style":_vm.overlayStyle,"ui":_vm.realUi,"autofocus":"","inline":_vm.inline,"modal":_vm.modal,"priority":_vm.priority},on:{"mousedown":_vm.focusContent,"afterclose":_vm.handleAfterClose}},_vm.listeners),[_c('div',_vm._b({directives:[{name:"drag",rawName:"v-drag:content.translate",value:({
      disabled: !_vm.draggable,
      containment: '@window',
      handle: 'head',
      ready: _vm.dragReady
    }),expression:"{\n      disabled: !draggable,\n      containment: '@window',\n      handle: 'head',\n      ready: dragReady\n    }",arg:"content",modifiers:{"translate":true}},{name:"outside",rawName:"v-outside",value:(_vm.outside),expression:"outside"}],ref:"content",class:_vm.$c('dialog-content'),attrs:{"tabindex":"-1"},on:{"mousedown":_vm.focus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.handleEscape.apply(null, arguments)}}},'div',_vm.attrs,false),[_c('div',{ref:"head",class:( _obj$1 = {}, _obj$1[_vm.$c('dialog-content-head')] = true, _obj$1[_vm.$c('dialog-draggable')] = _vm.draggable, _obj$1 )},[(_vm.title || _vm.$slots.title || _vm.$scopedSlots.title)?_c('h3',{class:_vm.$c('dialog-content-head-title')},[_vm._t("title",function(){return [_vm._v(_vm._s(_vm.title))]},{"close":_vm.close})],2):_vm._e(),(_vm.closable)?_c('veui-button',{class:_vm.$c('dialog-content-head-close'),attrs:{"ui":_vm.uiParts.close,"aria-label":_vm.t('close')},on:{"click":_vm.cancel}},[_c('veui-icon',{attrs:{"name":_vm.icons.close}})],1):_vm._e()],1),_c('div',{class:_vm.$c('dialog-content-body')},[_vm._t("default",null,{"close":_vm.close})],2),(!_vm.footless)?_c('div',{class:_vm.$c('dialog-content-foot')},[_vm._t("foot",function(){return [_c('veui-button',{attrs:{"ui":_vm.uiParts.ok,"disabled":_vm.disabled,"loading":_vm.loading},on:{"click":function($event){return _vm.close('ok')}}},[_vm._v(_vm._s(_vm.realOkLabel))]),_c('veui-button',{attrs:{"ui":_vm.uiParts.cancel,"autofocus":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.realCancelLabel)+" ")])]},{"close":_vm.close})],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }