import i18n from '../../managers/i18n'

i18n.register(
  'zh-Hans',
  {
    noData: '没有数据'
  },
  {
    ns: 'filterpanel'
  }
)
