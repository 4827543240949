<template>
<article class="veui-transfer-demo">
  <h1>
    <code>&lt;veui-tree&gt;</code>
  </h1>

  <h2>点击左侧图标展开收起</h2>
  <p>当前展开：{{ expanded1 }}</p>
  <veui-tree :datasource="treeDatasource1" :expanded.sync="expanded1"/>

  <h2>点击整行展开收起</h2>
  <veui-tree :datasource="treeDatasource2" item-click="toggle"/>

  <h1>
    <code>&lt;veui-filter-panel&gt;</code>
  </h1>

  <veui-filter-panel
    :datasource="treeDatasource1"
    class="veui-select-panel-demo1"
  >
    <template #head>列表</template>
    <template slot-scope="{ items }">
      <veui-tree :datasource="items">
        <template #item-label="props">
          <slot name="tree-item-label" v-bind="props">{{
            props.item.label
          }}</slot>
        </template>
      </veui-tree>
    </template>
  </veui-filter-panel>

  <h1>
    <code>&lt;veui-transfer&gt;</code>
  </h1>

  <h2>自定义项目内容</h2>
  <veui-transfer :datasource="datasource1" selected-show-mode="flat">
    <template #candidate-item-label="{ label }">
      <em>{{ label }}</em>
    </template>
    <template #selected-item-label="{ label }">
      <small>{{ label }}</small>
    </template>
  </veui-transfer>

  <h2>多级树形结构</h2>
  mergeChecked：
  <veui-radio-group
    v-model="strategy"
    class="check-strategy"
    :items="strategies"
  />
  <veui-transfer
    v-model="selected1"
    :merge-checked="
      strategy === 'include-indeterminate' ? 'keep-all' : strategy
    "
    :include-indeterminate="strategy === 'include-indeterminate'"
    :datasource="datasource1"
  >
    <template #candidate-title="{ count }">备选列表（{{ count }}）</template>
    <template #selected-title="{ count }">已选列表（{{ count }}）</template>
  </veui-transfer>
  <div>selected: {{ selected1 }}</div>
  <h2>单级结构</h2>
  <p>
    <veui-transfer v-model="selected2" :datasource="datasource2"/>
  </p>

  <h2>多级树形结构，右侧扁平</h2>
  <p>
    <veui-transfer
      v-model="selected3"
      :datasource="datasource3"
      selected-show-mode="flat"
      ui="s"
    >
      <template
        #candidate-title="{ count }"
      >备选列表（{{ count }}）</template>
      <template #selected-title="{ count }">已选列表（{{ count }}）</template>
    </veui-transfer>
  </p>

  <h2>单级结构，禁用</h2>
  <p>
    <veui-transfer v-model="selected4" :datasource="datasource4" disabled>
      <template #candidate-title>备选列表</template>
      <template #selected-title="{ count }">已选列表（{{ count }}）</template>
    </veui-transfer>
  </p>

  <h2>多级树形结构，禁用</h2>
  <p>
    <veui-transfer
      v-model="selected5"
      :datasource="datasource5"
      selected-show-mode="flat"
      disabled
    >
      <template
        #candidate-title="{ count }"
      >备选列表（{{ count }}）</template>
      <template #selected-title="{ count }">已选列表（{{ count }}）</template>
    </veui-transfer>
  </p>

  <h2>用于表单</h2>
  <veui-form :data="formData" :validators="validators">
    <veui-field
      label="咖啡："
      field="selected6"
      name="selected6"
      rules="required"
    >
      <veui-transfer
        v-model="formData.selected6"
        :datasource="datasource6"
        candidate-placeholder="搜索备选列表"
        selected-placeholder="搜索已选列表"
      >
        <template
          #candidate-title="{ count }"
        >备选列表（{{ count }}）</template>
        <template
          #selected-title="{ count }"
        >已选列表（{{ count }}）</template>
      </veui-transfer>
    </veui-field>
    <div class="operation">
      <veui-button ui="primary" type="submit">提交</veui-button>
    </div>
  </veui-form>

  <h2>自定义candidate</h2>
  <veui-transfer v-model="selected4" :datasource="datasource4">
    <template v-slot:candidate="{ datasource }">
      <veui-table
        class="custom-table"
        :data="datasource"
        selectable
        :selected.sync="selected4"
        key-field="value"
      >
        <veui-table-column field="value" title="ID"/>
        <veui-table-column field="label" title="Name"/>
      </veui-table>
    </template>
  </veui-transfer>
</article>
</template>

<script>
import {
  Transfer,
  Form,
  Field,
  Button,
  Tree,
  FilterPanel,
  Table,
  Column,
  RadioGroup
} from 'veui'
import { cloneDeep } from 'lodash'

export default {
  name: 'transfer-demo',
  components: {
    'veui-transfer': Transfer,
    'veui-form': Form,
    'veui-field': Field,
    'veui-button': Button,
    'veui-tree': Tree,
    'veui-filter-panel': FilterPanel,
    'veui-table': Table,
    'veui-table-column': Column,
    'veui-radio-group': RadioGroup
  },
  data () {
    let coffees = [
      {
        label: 'Infused',
        value: 'infused',
        children: [
          {
            label: 'Brewed',
            value: 'brewed',
            children: [
              {
                label: 'Drip brewed',
                value: 'drip-brewed'
              },
              {
                label: 'Filtered',
                value: 'filtered',
                disabled: true
              },
              {
                label: 'Pour-over',
                value: 'pour-over'
              },
              {
                label: 'Immersion brewed',
                value: 'immersion-brewed'
              }
            ]
          },
          {
            label: 'French press',
            value: 'french-press'
          },
          {
            label: 'Cold brew',
            value: 'cold-brew'
          }
        ]
      },
      {
        label: 'Boiled',
        value: 'boiled',
        disabled: true,
        children: [
          {
            label: 'Percolated',
            value: 'percolated'
          },
          {
            label: 'Turkish',
            value: 'turkish',
            disabled: true
          },
          {
            label: 'Moka',
            value: 'moka'
          }
        ]
      },
      {
        label: 'Espresso',
        value: 'espresso',
        children: [
          {
            label: 'Caffè Americano',
            value: 'caffe-americano'
          },
          {
            label: 'Cafe Lungo',
            value: 'cafe-lungo'
          },
          {
            label: 'Café Cubano',
            value: 'cafe-cubano'
          },
          {
            label: 'Caffè crema',
            value: 'caffe-crema'
          },
          {
            label: 'Cafe Zorro',
            value: 'cafe-zorro'
          },
          {
            label: 'Doppio',
            value: 'doppio'
          },
          {
            label: 'Espresso Romano',
            value: 'espresso-romano'
          },
          {
            label: 'Guillermo',
            value: 'guillermo'
          },
          {
            label: 'Ristretto',
            value: 'ristretto'
          }
        ]
      },
      {
        label: 'Milk coffee',
        value: 'milk-coffee',
        children: [
          {
            label: 'Flat white',
            value: 'flat-white'
          },
          {
            label: 'Latte',
            value: 'latte',
            disabled: true,
            children: [
              {
                label: 'Red Latte',
                value: 'red-latte'
              },
              {
                label: 'Green Latte',
                value: 'green-latte'
              }
            ]
          },
          {
            label: 'Macchiato',
            value: 'macchiato'
          },
          {
            label: 'Cappuccino',
            value: 'cappuccino'
          },
          {
            label: 'White coffee',
            value: 'white-coffee'
          }
        ]
      }
    ]

    let oneDepth = [
      {
        value: '1',
        label: '张三'
      },
      {
        value: '2',
        label: '李四'
      },
      {
        value: '3',
        label: '王五'
      }
    ]

    return {
      treeDatasource1: cloneDeep(coffees),
      treeDatasource2: cloneDeep(coffees),
      expanded1: ['10000', '1000'],
      strategy: 'keep-all',
      strategies: [
        { label: 'keep-all', value: 'keep-all' },
        { label: 'downwards', value: 'downwards' },
        { label: 'upwards', value: 'upwards' },
        { label: 'include-indeterminate', value: 'include-indeterminate' }
      ],

      selected1: ['filtered'],
      datasource1: cloneDeep(coffees),

      selected2: [],
      datasource2: cloneDeep(oneDepth),

      selected3: [],
      datasource3: cloneDeep(coffees),

      selected4: [],
      datasource4: cloneDeep(oneDepth),

      selected5: ['flat-white'],
      datasource5: cloneDeep(coffees),

      datasource6: cloneDeep(coffees),
      formData: {
        selected6: ['ristretto']
      },

      validators: [
        {
          fields: ['selected6'],
          handler (selected6) {
            if (!selected6 || !selected6.length) {
              return {
                selected6: '请选择地域'
              }
            }
            return true
          }
        }
      ]
    }
  },
  computed: {
    datasource1LeafCount () {
      return this.countLeaves(this.datasource1)
    }
  },
  methods: {
    countLeaves (tree) {
      let count = 0
      let walk = (tree) => {
        tree.forEach((item) => {
          if (!item.children) {
            count += 1
          } else {
            walk(item.children)
          }
        })
      }
      walk(tree)
      return count
    }
  }
}
</script>

<style lang="less" scoped>
.veui-transfer-demo {
  .operation {
    padding-left: 120px;
    margin-bottom: 100px;
  }

  .veui-select-panel-demo1 {
    .veui-tree {
      padding: 0 20px;
    }
  }

  .custom-table {
    width: 300px;
  }

  .check-strategy {
    display: inline-flex;
    margin-bottom: 12px;
  }
}
</style>
