var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$c('steps'),attrs:{"ui":_vm.realUi,"role":"list"}},[_vm._l((_vm.steps),function(step,index){
var _obj;
return [(index !== 0)?_c('div',{key:("connector-" + index),class:[
        _vm.$c('steps-step-connector'),
        _vm.$c(("steps-step-connector-" + (_vm.stepStatus[index])))
      ]}):_vm._e(),_c('veui-link',{key:("step-" + index),class:[
        _vm.$c('steps-step'),
        _vm.$c(("steps-step-" + (_vm.stepStatus[index]))),
        index < _vm.steps.length - 1
          ? _vm.$c(("steps-step-next-" + (_vm.stepStatus[index + 1])))
          : '',
        ( _obj = {}, _obj[_vm.$c('steps-step-activatable')] = !_vm.stateless && !!step.to, _obj )
      ],attrs:{"to":_vm.stateless ? null : step.to,"fallback":"div","role":"listitem","aria-current":index === _vm.current ? 'step' : null},on:{"click":function($event){return _vm.$emit('click', index, $event)}}},[_vm._t("default",function(){return [_c('div',{class:_vm.$c('steps-step-index-container')},[_c('div',{class:_vm.$c('steps-step-index')},[_vm._t("index",function(){return [(
                  !_vm.stateless && index < _vm.current && step.status !== 'error'
                )?_c('veui-icon',{attrs:{"name":_vm.icons.success}}):(!_vm.stateless && step.status === 'error')?_c('veui-icon',{attrs:{"name":_vm.icons.error}}):[_vm._v(_vm._s(index + 1))]]},{"index":index},step)],2),(index < _vm.stepStatus.length - 1)?_c('div',{class:[
              _vm.$c('steps-step-connector-placeholder'),
              _vm.$c(("steps-step-connector-placeholder-" + (_vm.stepStatus[index + 1])))
            ]}):_vm._e()]),(step.label)?_c('div',{class:_vm.$c('steps-step-content')},[_c('div',{class:_vm.$c('steps-step-label')},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(step.label)+" ")]},{"index":index},step)],2),(step.desc)?_c('div',{class:_vm.$c('steps-step-desc')},[_vm._t("desc",function(){return [_vm._v(" "+_vm._s(step.desc)+" ")]},{"index":index},step)],2):_vm._e()]):_vm._e()]},{"index":index},step)],2)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }