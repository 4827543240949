var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {}, _obj[_vm.$c('menu')] = true, _obj[_vm.$c('menu-collapsed')] = _vm.realCollapsed, _obj ),attrs:{"ui":_vm.realUi}},[_c('div',{class:_vm.$c('menu-tree-wrapper')},[_vm._t("before"),_c('abstract-tree',{ref:"tree",class:( _obj$1 = {}, _obj$1[_vm.$c('menu-tree')] = true, _obj$1[_vm.$c('menu-tree-has-icon')] = _vm.hasIcon, _obj$1 ),attrs:{"ui":_vm.realUi,"items":_vm.normalizedItems,"expanded":_vm.realExpanded},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var link = ref.item;
      var itemExpanded = ref.expanded;
      var depth = ref.depth;
return [_vm._t("item",function(){
      var _obj;
return [_c('div',{ref:link.name,class:Object.assign(( _obj = {}, _obj[_vm.$c('menu-item')] = true, _obj[_vm.$c('menu-item-expanded')] = itemExpanded, _obj[_vm.$c('menu-tree-item')] = true, _obj ),
              _vm.itemClass(link)),on:{"mouseenter":function($event){return _vm.show(link)}}},[_c('veui-link',_vm._b({class:_vm.$c('menu-link'),attrs:{"disabled":!!link.disabled,"ui":_vm.realUi,"tabindex":depth === 1 ? link.tabIndex : link.disabled ? null : '-1'},on:{"click":function($event){return _vm.handleItemClick(link)},"keydown":function($event){return _vm.handleKeydown($event, link)}}},'veui-link',_vm.pickLinkProps(link),false),[(link.icon || _vm.$scopedSlots.icon || _vm.$slots.icon)?_c('span',{class:_vm.$c('menu-item-icon')},[_vm._t("icon",function(){return [_c('veui-icon',{attrs:{"ui":_vm.realUi,"name":link.icon}})]},null,link)],2):_vm._e(),_c('span',{class:_vm.$c('menu-item-label')},[(!_vm.realCollapsed)?_vm._t("item-label",function(){return [_vm._v(" "+_vm._s(link.label)+" ")]},null,link):_vm._e()],2)]),(
                !_vm.realCollapsed &&
                  !link.disabled &&
                  link.children &&
                  link.children.length
              )?_c('veui-button',{class:_vm.$c('menu-item-toggle'),attrs:{"ui":_vm.uiParts.toggle,"tabindex":"-1"},on:{"click":function($event){return _vm.toggleExpanded(link)}}},[_c('veui-icon',{class:_vm.$c('menu-toggle-icon'),attrs:{"name":link.expanded ? _vm.icons.collapse : _vm.icons.expand}})],1):_vm._e()],1)]},{"expanded":itemExpanded},link),(_vm.realCollapsed && link.options && link.options.length)?_c('veui-overlay',{attrs:{"target":link.name,"options":_vm.realOverlayOptions,"open":!!_vm.hoverItem && _vm.hoverItem.name === link.name,"position":"right-start","overlay-class":_vm.$c('menu-overlay'),"trigger":"hover","autofocus":""}},[_c('div',{directives:[{name:"outside",rawName:"v-outside",value:({
              refs: _vm.outsideRefs,
              delay: 100,
              trigger: 'hover',
              handler: _vm.close
            }),expression:"{\n              refs: outsideRefs,\n              delay: 100,\n              trigger: 'hover',\n              handler: close\n            }"}],class:_vm.$c('menu-popout')},[_c('div',{class:_vm.$c('menu-group-title')},[_vm._v(_vm._s(link.label))]),_c('veui-option-group',{ref:("dropdown-" + (link.name)),attrs:{"overlay-class":_vm.$c('menu-overlay'),"options":link.options,"position":"popup","trigger":"hover","option-tag":"div"},nativeOn:{"keydown":function($event){return _vm.handleKeydown($event, null, link)}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._t("item",function(){
            var _obj;
return [_c('veui-link',_vm._b({class:Object.assign(( _obj = {}, _obj[_vm.$c('menu-item')] = true, _obj[_vm.$c('menu-link')] = true, _obj ),
                      _vm.itemClass(option)),attrs:{"tabindex":option.disabled ? null : 0},on:{"click":function($event){return _vm.handleItemClick(option)}}},'veui-link',_vm.pickLinkProps(option),false),[_vm._t("item-label",function(){return [_c('span',{class:_vm.$c('menu-item-label')},[_vm._v(" "+_vm._s(option.label)+" ")])]},null,option)],2)]},null,option)]}},{key:"option-group-label",fn:function(group){return [_vm._t("item",function(){
                    var _obj;
return [_c('veui-link',_vm._b({class:Object.assign(( _obj = {}, _obj[_vm.$c('menu-item')] = true, _obj[_vm.$c('menu-link')] = true, _obj ),
                      _vm.itemClass(group.option)),attrs:{"tabindex":group.option.disabled ? null : 0},on:{"click":function($event){return _vm.handleGroupLabelClick(group.option, group.closeMenu)}}},'veui-link',_vm.pickLinkProps(group.option),false),[_vm._t("item-label",function(){return [_c('span',{class:_vm.$c('menu-item-label')},[_vm._v(" "+_vm._s(group.label)+" ")])]},null,group)],2)]},null,group)]}}],null,true)})],1)]):_vm._e()]}}],null,true)}),_vm._t("after")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }