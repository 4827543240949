var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$c('nav'),attrs:{"ui":_vm.realUi}},[_c('ul',{directives:[{name:"resize",rawName:"v-resize.debounce.leading.200",value:(_vm.renderAllThenUpdateLayout),expression:"renderAllThenUpdateLayout",modifiers:{"200":true,"debounce":true,"leading":true}}],ref:"body",class:_vm.$c('nav-body')},[_vm._l((_vm.realItems),function(item){
var _obj, _obj$1, _obj$2;
return _c('li',{key:item.name,ref:item.name,refInFor:true,class:( _obj = {}, _obj[_vm.$c('nav-more-hidden')] = _vm.isMoreBtn(item) && !_vm.restItems.length, _obj )},[(_vm.isMoreBtn(item))?_vm._t("more",function(){
      var _obj;
return [_c('div',{ref:"more",refInFor:true,class:Object.assign(( _obj = {}, _obj[_vm.$c('nav-more')] = true, _obj[_vm.$c('nav-item')] = !!_vm.restItems.length, _obj[_vm.$c('nav-item-open')] = _vm.isOpen('more'), _obj ),
            _vm.itemClass(item)),attrs:{"tabindex":item.tabIndex},on:{"mouseenter":function($event){return _vm.handleItemHover(item)},"keydown":function($event){return _vm.handleKeydown($event, item)}}},[_vm._t("more-icon",function(){return [_c('veui-icon',{attrs:{"name":_vm.icons.more}})]})],2)]}):_vm._t("item",function(){
          var _obj;
return [_c('veui-link',_vm._b({ref:("link-" + (item.name)),refInFor:true,class:Object.assign(( _obj = {}, _obj[_vm.$c('nav-item')] = true, _obj[_vm.$c('nav-item-open')] = _vm.isOpen(item.name), _obj[_vm.$c('nav-expandable')] = _vm.hasChildren(item), _obj ),
            _vm.itemClass(item)),attrs:{"tabindex":item.tabIndex},on:{"mouseenter":function($event){return _vm.handleItemHover(item)},"mouseleave":function($event){return _vm.handleItemLeave(item)},"click":function($event){return _vm.handleItemClick(item)},"keydown":function($event){return _vm.handleKeydown($event, item)}}},'veui-link',_vm.pickLinkProps(item),false),[_c('span',{ref:("label-" + (item.name)),refInFor:true,class:_vm.$c('nav-item-label-wrapper')},[_vm._t("item-label",function(){return [_vm._v(_vm._s(item.label))]},null,item)],2),(_vm.hasChildren(item))?_vm._t("item-icon",function(){return [_c('veui-icon',{class:_vm.$c('nav-expand-icon'),attrs:{"name":_vm.icons.expand}})]},null,item):_vm._e()],2)]},null,item),(!!item.children)?_c('veui-overlay',{attrs:{"overlay-class":( _obj$1 = {}, _obj$1[_vm.$c('nav-overlay')] = true, _obj$1[_vm.$c('nav-more-overlay')] = _vm.isMoreBtn(item), _obj$1 ),"open":_vm.isOpen(item.name),"target":item.name,"position":"bottom-center","autofocus":""}},[_c('div',{directives:[{name:"outside",rawName:"v-outside",value:({
            refs: _vm.outsideRefs,
            delay: 100,
            trigger: 'hover',
            handler: function () { return _vm.close(item); }
          }),expression:"{\n            refs: outsideRefs,\n            delay: 100,\n            trigger: 'hover',\n            handler: () => close(item)\n          }"}],ref:("dropdown-" + (item.name)),refInFor:true,class:_vm.$c('nav-dropdown'),on:{"keydown":function($event){return _vm.handleKeydown($event, null, item)}}},[(item.position === 'card')?_c('ul',{class:( _obj$2 = {}, _obj$2[_vm.$c('nav-card')] = true, _obj$2 )},_vm._l((item.children),function(cardItem){return _c('li',{key:cardItem.name,class:_vm.$c('nav-card-item'),on:{"click":function($event){return _vm.handleItemClick(cardItem)}}},[_vm._t("item",function(){
            var _obj;
return [_c('veui-link',_vm._b({class:Object.assign(( _obj = {}, _obj[_vm.$c('nav-item')] = true, _obj[_vm.$c('nav-item-title')] = true, _obj[_vm.$c('nav-item-has-icon')] = cardItem.icon, _obj ),
                    _vm.itemClass(cardItem)),attrs:{"tabindex":!cardItem.disabled && cardItem.to ? 0 : null}},'veui-link',_vm.pickLinkProps(cardItem),false),[(
                      cardItem.icon ||
                        _vm.$slots['title-icon'] ||
                        _vm.$scopedSlots['title-icon']
                    )?_vm._t("title-icon",function(){return [_c('veui-icon',{class:_vm.$c('nav-title-icon'),attrs:{"name":cardItem.icon}})]}):_vm._e(),_vm._t("item-label",function(){return [_vm._v(" "+_vm._s(cardItem.label)+" ")]},null,cardItem)],2)]},null,cardItem),(_vm.hasChildren(cardItem))?_c('ul',{attrs:{"role":"group"}},_vm._l((cardItem.children),function(subCardItem){return _c('li',{key:subCardItem.name,on:{"click":function($event){return _vm.handleItemClick(subCardItem)}}},[_vm._t("item",function(){
                    var _obj;
return [_c('veui-link',_vm._b({class:Object.assign(( _obj = {}, _obj[_vm.$c('nav-item')] = true, _obj ),
                        _vm.itemClass(subCardItem)),attrs:{"tabindex":subCardItem.disabled ? null : 0}},'veui-link',_vm.pickLinkProps(subCardItem),false),[_vm._t("item-label",function(){return [_vm._v(" "+_vm._s(subCardItem.label)+" ")]},null,subCardItem)],2)]},null,subCardItem)],2)}),0):_vm._e()],2)}),0):_c('veui-option-group',{attrs:{"overlay-class":_vm.$c('nav-overlay'),"options":item.children,"position":"popup","trigger":"hover","option-tag":"div"},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._t("item",function(){
                      var _obj;
return [_c('veui-link',_vm._b({class:Object.assign(( _obj = {}, _obj[_vm.$c('nav-item')] = true, _obj ),
                    _vm.itemClass(option)),attrs:{"tabindex":option.disabled ? null : 0},on:{"click":function($event){return _vm.handleItemClick(option)}}},'veui-link',_vm.pickLinkProps(option),false),[_vm._t("item-label",function(){return [_vm._v(" "+_vm._s(option.label)+" ")]},null,option)],2)]},null,option)]}},{key:"option-group-label",fn:function(group){return [_vm._t("item",function(){
                  var _obj;
return [_c('veui-link',_vm._b({class:Object.assign(( _obj = {}, _obj[_vm.$c('nav-item')] = true, _obj ),
                    _vm.itemClass(group.option)),attrs:{"tabindex":group.option.disabled ? null : 0},on:{"click":function($event){return _vm.handleGroupLabelClick(group.option, group.closeMenu)}}},'veui-link',_vm.pickLinkProps(group.option, true),false),[_c('span',{class:_vm.$c('nav-item-label-wrapper')},[_vm._t("item-label",function(){return [_vm._v(" "+_vm._s(group.label)+" ")]},null,group)],2)])]},null,group)]}}],null,true)})],1)]):_vm._e()],2)}),_c('li',{class:( _obj = {}, _obj[_vm.$c('nav-indicator')] = true, _obj[_vm.hoverIndicatorClass] = true, _obj ),style:(_vm.hoverIndicatorPosition != null
          ? ("transform: translateX(" + _vm.hoverIndicatorPosition + "px)")
          : null),attrs:{"aria-hidden":"true"}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }