import i18n from '../../managers/i18n'

i18n.register(
  'en-US',
  {
    placeholder: 'Pick a time',
    clear: 'Clear'
  },
  {
    ns: 'timepicker'
  }
)
