<template>
<div :class="$c('input-group')" :ui="realUi">
  <slot/>
</div>
</template>

<script>
import 'veui/locale/zh-Hans/common.js'
import 'veui/locale/en-US/common.js'
import 'focus-visible'
import 'veui-theme-dls/components/InputGroup.js'

import ui from '../mixins/ui'
import prefix from '../mixins/prefix'
import '../common/global'

export default {
  name: 'veui-input-group',
  mixins: [ui, prefix]
}
</script>
<style lang="less" src="veui-theme-dls/components/input-group.less"></style>
