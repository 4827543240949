var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('abstract-tree',{class:( _obj = {}, _obj[_vm.$c('tree')] = true, _obj[_vm.$c('disabled')] = _vm.realDisabled || _vm.realReadonly, _obj ),attrs:{"group-class":_vm.$c('tree-item-group'),"ui":_vm.realUi,"items":_vm.normalizedItems,"expanded":_vm.realExpanded},nativeOn:{"focusin":function($event){_vm.focused = true},"focusout":function($event){_vm.focused = false}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var _obj;

  var item = ref.item;
  var index = ref.index;
  var depth = ref.depth;
  var itemExpanded = ref.expanded;
  var parents = ref.parents;return [_c('div',{ref:"item",class:( _obj = {}, _obj[_vm.$c('tree-item')] = true, _obj[_vm.$c('tree-item-expanded')] = itemExpanded, _obj[_vm.$c('tree-item-selected')] = _vm.selectable && _vm.isSelected(item), _obj[_vm.$c('tree-item-clickable')] = _vm.isClickable(item), _obj[_vm.$c('tree-item-hidden')] = item.hidden, _obj[_vm.$c('disabled')] = _vm.isDisabled(item, parents), _obj ),attrs:{"tabindex":depth === 1 ? item.tabIndex : item.disabled ? null : '-1'},on:{"click":function($event){return _vm.handleItemClick(item, parents, index, depth)},"keydown":function($event){return _vm.handleKeydown($event, item, index, depth)}}},[_vm._t("item",function(){return [(item.children && item.children.length)?_c('button',{class:_vm.$c('tree-item-expand-switcher'),attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleExpanded(item, index, depth)}}},[_c('veui-icon',{attrs:{"name":_vm.icons.collapse}})],1):_vm._e(),_vm._t("item-before",function(){return [(_vm.checkable)?_c('veui-checkbox',{class:_vm.$c('tree-item-check'),attrs:{"checked":item.checked,"indeterminate":item.partialChecked,"disabled":_vm.realReadonly || _vm.isDisabled(item, parents),"ui":_vm.realUi,"tabindex":"-1"},on:{"change":function($event){return _vm.handleItemCheck(item, parents)}},nativeOn:{"click":function($event){$event.stopPropagation();}}}):_vm._e()]},{"item":item,"index":index,"depth":depth,"expanded":itemExpanded,"parents":parents},item),_c('div',{class:_vm.$c('tree-item-label')},[_vm._t("item-label",function(){return [_vm._v(_vm._s(item.label))]},{"item":item,"index":index,"depth":depth,"expanded":itemExpanded,"parents":parents},item)],2),_vm._t("item-after",null,{"item":item,"index":index,"depth":depth,"expanded":itemExpanded,"parents":parents},item)]},{"item":item,"index":index,"depth":depth,"expanded":itemExpanded,"parents":parents},item)],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }