var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"main",class:( _obj = {}, _obj[_vm.$c('dropdown')] = true, _obj[_vm.$c('dropdown-expanded')] = _vm.realExpanded, _obj[_vm.$c('dropdown-split')] = _vm.split, _obj ),attrs:{"ui":_vm.realUi}},[_vm._t("trigger",function(){return [(_vm.split)?_c('veui-button',{ref:"command",class:_vm.$c('dropdown-command'),attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_c('span',{class:_vm.$c('dropdown-label')},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label))]},{"label":_vm.label})],2)]):_vm._e(),_c('veui-button',_vm._g(_vm._b({ref:"button",class:_vm.$c('dropdown-button')},'veui-button',_vm.triggerAttrs,false),_vm.triggerHandlers),[(!_vm.split)?_c('span',{class:_vm.$c('dropdown-label')},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label))]},{"label":_vm.label})],2):_vm._e(),_c('veui-icon',{class:_vm.$c('dropdown-icon'),attrs:{"name":_vm.icons[_vm.realExpanded ? 'collapse' : 'expand']}})],1)]},null,{
      attrs: _vm.triggerAttrs,
      handlers: _vm.triggerHandlers,
      expanded: _vm.realExpanded,
      toggle: _vm.toggleExpanded
    }),_c('veui-overlay',{attrs:{"target":"main","open":_vm.realExpanded,"autofocus":"","modal":"","match-width":"","overlay-class":_vm.mergeOverlayClass(_vm.$c('dropdown-box')),"overlay-style":_vm.overlayStyle,"local":_vm.realOverlayOptions.local,"options":_vm.realOverlayOptions,"priority":_vm.overlayPriority}},[_c('div',{directives:[{name:"outside",rawName:"v-outside",value:({
        refs: _vm.outsideRefs,
        handler: _vm.close,
        trigger: _vm.trigger,
        delay: 300
      }),expression:"{\n        refs: outsideRefs,\n        handler: close,\n        trigger,\n        delay: 300\n      }"}],ref:"box",class:_vm.$c('dropdown-options'),attrs:{"id":_vm.dropdownId,"ui":_vm.realUi,"role":"menu","tabindex":_vm.searchable ? -1 : 0,"aria-expanded":_vm.realExpanded},on:{"keydown":_vm.handleKeydown,"focus":function($event){return _vm.focusAt(0)}}},[(_vm.searchable)?_c('veui-search-box',{class:_vm.$c('dropdown-search-box'),attrs:{"ui":_vm.uiParts.search,"placeholder":_vm.placeholder,"clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnter.apply(null, arguments)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}):_vm._e(),(_vm.isSearching && !_vm.filteredSuggestions.length)?_c('div',{class:_vm.$c('dropdown-options-no-data')},[_vm._t("no-data",function(){return [_vm._v(_vm._s(_vm.t('noData')))]},null,{ keyword: _vm.keyword })],2):_c('veui-option-group',{ref:"options",class:( _obj$1 = {}, _obj$1[_vm.$c('dropdown-options-custom')] = (_vm.$slots.default || _vm.$scopedSlots.default) &&
            (!_vm.options || !_vm.options.length), _obj$1 ),attrs:{"options":_vm.isSearching ? _vm.filteredSuggestions : _vm.options,"trigger":_vm.trigger,"overlay-class":_vm.overlayClass,"overlay-style":_vm.overlayStyle},scopedSlots:_vm._u([{key:"label",fn:function(group){return (_vm.$scopedSlots['group-label'])?[_vm._t("group-label",null,null,group)]:undefined}},{key:"option",fn:function(option){return (_vm.$scopedSlots.option)?[_vm._t("option",null,null,option)]:undefined}},{key:"option-label",fn:function(option){return [_vm._t("option-label",function(){return [(!!_vm.searchable && _vm.keyword)?[_vm._l((option.matches),function(ref,idx){
        var parts = ref.parts;
return [_vm._l((parts),function(ref,index){
        var text = ref.text;
        var matched = ref.matched;
return [(matched)?_c('mark',{key:("m-" + idx + "-" + index),class:_vm.$c('option-matched')},[_vm._v(_vm._s(text))]):_c('span',{key:("s-" + idx + "-" + index)},[_vm._v(_vm._s(text))])]}),(idx < option.matches.length - 1)?_c('span',{key:idx,class:_vm.$c('option-separator')},[_vm._v(">")]):_vm._e()]})]:_vm._e()]},null,option)]}}],null,true)},[_vm._t("default",null,{"close":_vm.close})],2)],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }