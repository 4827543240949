var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.sliderClasses,attrs:{"ui":_vm.realUi,"role":"application","tabindex":"-1"}},[_c('div',{ref:"track",class:_vm.$c('slider-track'),on:{"click":_vm.handleTrackClick}},[_vm._t("track",function(){return [_c('div',{class:_vm.$c('slider-track-default')},[_c('div',{class:_vm.$c('slider-track-default-wrapper')},[_c('div',{class:[
              _vm.$c('slider-track-default-bg'),
              _vm.$c('slider-track-default-progress')
            ]}),_c('div',{class:[
              _vm.$c('slider-track-default-sp'),
              _vm.$c('slider-track-default-progress')
            ],style:(_vm.secondaryProgressStyle)}),_c('div',{class:[
              _vm.$c('slider-track-default-fg'),
              _vm.$c('slider-track-default-progress')
            ],style:(_vm.progressStyle)}),(_vm.stepMarks)?_c('div',{class:_vm.$c('slider-track-default-marks')},_vm._l((_vm.stepMarks),function(mk){
            var _obj;
return _c('div',{key:mk,class:( _obj = {}, _obj[_vm.$c('slider-track-default-mark')] = true, _obj[_vm.$c(
                  ("slider-track-default-mark-" + (_vm.progressRange[0] <= mk && mk <= _vm.progressRange[1]
                      ? 'in-progress'
                      : 'out-progress'))
                )] = true, _obj ),style:({
                left: ((mk * 100) + "%")
              }),on:{"click":function($event){return _vm.handleMarkClick($event, mk)}}})}),0):_vm._e()])])]})],2),_vm._l((new Array(_vm.thumbCount)),function(_,index){return _c('button',_vm._b({directives:[{name:"outside",rawName:"v-outside.hover",value:(function () { return _vm.handleThumbMouseLeave(index); }),expression:"() => handleThumbMouseLeave(index)",modifiers:{"hover":true}},{name:"drag",rawName:"v-drag",value:(_vm.thumbDragOptions[index]),expression:"thumbDragOptions[index]"},{name:"nudge",rawName:"v-nudge",value:({
      step: _vm.keyboardChangeStep,
      update: function () {
          var args = [], len = arguments.length;
          while ( len-- ) args[ len ] = arguments[ len ];

          return _vm.handleThumbNudgeUpdage.apply(void 0, [ index ].concat( args ));
              }
    }),expression:"{\n      step: keyboardChangeStep,\n      update: (...args) => handleThumbNudgeUpdage(index, ...args)\n    }"}],key:("thumb" + index),ref:"thumb",refInFor:true,class:_vm.$c('slider-thumb'),style:({
      left: ((_vm.ratios[index] * 100) + "%")
    }),attrs:{"type":"button","disabled":_vm.realDisabled,"role":"slider"},on:{"mouseenter":function($event){return _vm.handleThumbMouseEnter(index)},"mousedown":function($event){return _vm.handleThumbFocus(index)},"focus":function($event){return _vm.handleThumbFocus(index)},"blur":_vm.handleThumbBlur}},'button',_vm.thumbAttrs[index],false),[_vm._t("thumb",function(){return [_c('div',{class:_vm.$c('slider-thumb-default')})]},{"index":index,"focus":_vm.currentThumbFocusIndex === index,"hover":_vm.currentThumbHoverIndex === index,"dragging":_vm.currentThumbDraggingIndex === index})],2)}),_vm._t("tip",function(){return [_c('veui-tooltip',{ref:"tip",attrs:{"target":_vm.tooltipTarget,"open":_vm.activeTooltipIndex >= 0,"overlay-class":_vm.$c('slider-tooltip'),"trigger":"custom","interactive":false,"ui":_vm.uiParts.tooltip}},[_vm._t("tip-label",function(){return [_vm._v(_vm._s(_vm.tooltipLabel))]})],2)]},{"target":_vm.tooltipTarget,"open":_vm.activeTooltipIndex >= 0,"activeIndex":_vm.activeTooltipIndex})],2)}
var staticRenderFns = []

export { render, staticRenderFns }