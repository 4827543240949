var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ol',{class:( _obj = {}, _obj[_vm.$c('carousel-items')] = true, _obj[_vm.$c('carousel-items-slide')] = true, _obj[_vm.$c('carousel-items-vertical')] = _vm.vertical, _obj ),on:{"transitionend":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.handleTransitionEnd.apply(null, arguments)},"transitioncancel":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.handleTransitionEnd.apply(null, arguments)}}},_vm._l((_vm.realDatasource),function(item,rdIndex){
  var _obj;
return _c('li',{key:rdIndex,ref:"item",refInFor:true,class:( _obj = {}, _obj[_vm.$c('carousel-item')] = true, _obj[_vm.$c('carousel-item-duplicate')] = item.duplicate, _obj[_vm.$c('carousel-item-pad')] = item.pad, _obj[_vm.$c('carousel-item-current')] = _vm.currentRdIndexes.indexOf(rdIndex) >= 0, _obj ),style:(_vm.dimension),attrs:{"tabindex":"-1"}},[(_vm.shouldRenderItem(item, rdIndex))?_vm._t("item",function(){return [_c('div',{class:_vm.$c('carousel-item-media'),style:(Object.assign({}, _vm.aspectRatioStyle,
          (item.raw.type !== 'video' && {
            'background-image': ("url(" + (item.raw.src) + ")")
          })))},[(item.raw.type === 'video')?_c('video',_vm._b({ref:("video#" + rdIndex),refInFor:true,class:_vm.$c('carousel-item-video'),attrs:{"src":item.raw.src,"alt":item.raw.alt,"preload":"auto","tabindex":"-1","autoplay":_vm.currentRdIndexes.indexOf(rdIndex) >= 0 && _vm.options.video.autoplay},domProps:{"muted":_vm.isAutoplay || _vm.options.video.muted}},'video',_vm.options.video,false)):_c('img',{class:_vm.$c('sr-only'),attrs:{"src":item.raw.src,"alt":item.raw.alt}})])]},{"index":item.index},Object.assign({}, item.raw, {preload: _vm.isPreload(item.index)})):_vm._e()],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }