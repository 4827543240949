import { render, staticRenderFns } from "./Lightbox.vue?vue&type=template&id=671e3976&"
import script from "./Lightbox.vue?vue&type=script&lang=js&"
export * from "./Lightbox.vue?vue&type=script&lang=js&"
import style0 from "veui-theme-dls/components/lightbox.less?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.8_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_vue-template-compiler@2.6.14_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports