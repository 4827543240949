var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$c('uploader-controls')},[_vm._l((_vm.items),function(control,controlIndex){return [(
        control.children &&
          control.children.length &&
          !(control.disabled != null ? control.disabled : _vm.disabled)
      )?_c('veui-dropdown',{key:((control.label) + "-" + controlIndex),class:_vm.$c('control-item'),attrs:{"options":control.children,"expanded":_vm.expanded,"trigger":"hover"},on:{"click":_vm.handleButtonClick,"toggle":_vm.handleDropdownToggle},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var props = ref.props;
      var handlers = ref.handlers;
return [_c('veui-button',_vm._g(_vm._b({key:control.name,attrs:{"ui":_vm.buttonUi,"aria-label":control.label}},'veui-button',props,false),handlers),[_c('veui-icon',{attrs:{"name":control.icon}}),(_vm.showLabel)?[_vm._v(_vm._s(control.label))]:_vm._e()],2)]}}],null,true)}):(!(control.disabled != null ? control.disabled : _vm.disabled))?_c('veui-button',{key:control.name,class:_vm.$c('control-item'),attrs:{"ui":_vm.buttonUi,"aria-label":control.label},on:{"click":function($event){return _vm.handleButtonClick(control.name)}}},[_c('veui-icon',{attrs:{"name":control.icon}}),(_vm.showLabel)?[_vm._v(_vm._s(control.label))]:_vm._e()],2):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }