<template>
<header
  :class="{
    [$c('layout-header')]: true,
    [$c('layout-header-sticky')]: sticky
  }"
>
  <slot/>
</header>
</template>

<script>
import 'veui/locale/zh-Hans/common.js'
import 'veui/locale/en-US/common.js'
import 'focus-visible'

import prefix from '../../mixins/prefix'

export default {
  name: 'veui-header',
  mixins: [prefix],
  props: {
    sticky: Boolean
  }
}
</script>
<style lang="less" src="veui-theme-dls/components/header.less"></style>
