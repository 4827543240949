import i18n from '../../managers/i18n'

i18n.register(
  'zh-Hans',
  {
    placeholder: '请选择时间',
    clear: '清除'
  },
  {
    ns: 'timepicker'
  }
)
