var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{class:( _obj = {}, _obj[_vm.$c('carousel-items')] = true, _obj[_vm.$c('carousel-items-fade')] = true, _obj ),attrs:{"name":_vm.$c('carousel-item'),"tag":"ol"},on:{"after-leave":_vm.handleTransitionEnd}},_vm._l((_vm.datasource),function(item,idx){
  var _obj;
return _c('li',{directives:[{name:"show",rawName:"v-show",value:(idx === _vm.index),expression:"idx === index"}],key:("item#" + idx),ref:"item",refInFor:true,class:( _obj = {}, _obj[_vm.$c('carousel-item')] = true, _obj[_vm.$c('carousel-item-current')] = idx === _vm.index, _obj ),attrs:{"tabindex":"-1"},on:{"focusin":function($event){_vm.focusedIndex = idx},"focusout":function($event){_vm.focusedIndex = null}}},[(idx === _vm.index || _vm.isPreload(idx))?_vm._t("item",function(){return [_c('div',{class:_vm.$c('carousel-item-media'),style:(Object.assign({}, _vm.aspectRatioStyle,
          (item.type !== 'video' && {
            'background-image': ("url(" + (item.src) + ")")
          })))},[(item.type === 'video')?_c('video',_vm._b({ref:("video#" + idx),refInFor:true,class:_vm.$c('carousel-item-video'),attrs:{"src":item.src,"alt":item.alt,"preload":"auto","tabindex":"-1","autoplay":idx === _vm.index && _vm.options.video.autoplay},domProps:{"muted":_vm.isAutoplay || _vm.options.video.muted}},'video',_vm.options.video,false)):_c('img',{class:_vm.$c('sr-only'),attrs:{"src":item.src,"alt":item.alt}})])]},{"index":idx},Object.assign({}, item, {preload: _vm.isPreload(idx)})):_vm._e()],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }