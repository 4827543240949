import i18n from '../../managers/i18n'

i18n.register(
  'en-US',
  {
    remove: 'Remove {label}',
    add: 'Add'
  },
  {
    ns: 'tabs'
  }
)
